import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { Spin } from "antd";
import "./App.css";
// import AdminLayout from "./layout/AdminLayout";
// import RaceGoalsLayout from "./layout/RaceGoalsLayout";
// import Login from "./components/login/Login";
// import ForgotPassword from "./components/forgot-password/ForgotPassword";
// import ResetPassword from "./components/reset-password/ResetPassword";
const Login = lazy(() => import("./components/login/Login"));
const ForgotPassword = lazy(() =>
  import("./components/forgot-password/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./components/reset-password/ResetPassword")
);
const AdminLayout = lazy(() => import("./layout/AdminLayout"));
const RaceGoalsLayout = lazy(() => import("./layout/RaceGoalsLayout"));

const ProtectedRoute = ({ children }) => {
  const admin = sessionStorage.getItem("admin");
  return admin ? children : <Navigate to="/admin-login" />;
};

function App() {
  return (
    <div className="App">
      <div style={{ width: "100%", margin: "auto" }}>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Spin size="large" />
            </div>
          }
        >
          <Routes>
            <Route path="/*" element={<RaceGoalsLayout />} />
            <Route
              path="admin/*"
              element={
                <ProtectedRoute>
                  <AdminLayout />
                </ProtectedRoute>
              }
            />
            <Route path="admin-login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
